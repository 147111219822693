.section {
  padding: $section-padding-y $section-padding-x;
  &.section-blue {
    color: $section-blue-foreground;
    background: $section-blue-background;
  }

  &.section-grey {
    color: $body-color-darkest;
    background: $section-grey-background;
    a {
      color: $darkest-blue;
    }
  }

  &.section-card {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    &.section-card-offset-top {
      .row {
        margin-top: -120px;
      }
      .section-content {
        @include media-breakpoint-up(lg) {
          padding: {
            left: 0;
            right: 0;
          }
        }
      }
    }
    .section-content {
      max-width: $section-content-max-width;
      background: $white;
      border-radius: 0;
      padding: 45px 30px;
      min-height: 115px;
      @include media-breakpoint-down(sm) {
        border-radius: 0;
      }
    }
  }

  .section-content {
    max-width: $section-content-max-width;
    margin: 0 auto;

    &.section-content-thin {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}