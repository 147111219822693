.card {
  border-radius: 0;
  .card-body {
    text-align: center;
    padding: 2rem 1.25rem;
  }

  &.card-dark {
    color: $card-dark-foreground;
    background-color: $card-dark-background;
    a {
      color: $card-dark-link;
      &:hover {
        color: $card-dark-link-hover;
      }
    }
  }

  &.card-light {
    a {
      color: $card-light-link;
      font-weight: $font-weight-medium
    }
    color: #575757;
    background-color: $card-light-background;
    p {
      font-size: .875rem;
    }
  }
  // specific blog card styles
  &.blog-card {
    .card-body {
      padding: 0;
    }
    article.post {
      padding: 2rem 1.25rem;
    }
  }
}

// landing page promo cards
.promo-cards {
  figure {
    text-align: center;
    height: 90px;
    img {
      width: auto;
      max-height: 90px;
    }
  }
  h5 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
}