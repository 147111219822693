.site-footer {
  padding: 24px 0 34px 0;
  .social-links {
      color: $footer-foreground;
      list-style: none;
      padding: 0;
      margin: 0;
      a {        
          font-size: 0.75rem; 
          color: $footer-link-color;
          &:hover {
              color: $footer-link-color;
              text-decoration: underline;
          }
      }
      li {
          display: inline-block;
          padding: 5px;          
          &:hover {
            color: $footer-link-color;
            a {
              text-decoration: underline;
            }
          }
      }
  }
  .copyright {
    font-size: 0.75rem; 
    font-weight: $font-weight-light;
    color: $footer-copyright;
  }
  .logo {
    max-width: 146px;
    height: auto;
    margin-left: 30px;
  }
  .vm-logo {
    font-size: .75rem;
    img {
      max-width: 75px;
      margin-left: 30px;
    }
  }
}