// Metropolis

@font-face {
  font-family: "Metropolis";
  src: local("Metropolis Regular"), local("Metropolis-Regular"),
    url("#{$path-fonts}/Metropolis/Metropolis-Regular.woff") format("woff");
  font-weight: $font-weight-normal;
}

@font-face {
  font-family: "Metropolis";
  src: local("Metropolis Light"), local("Metropolis-Light"),
    url("#{$path-fonts}/Metropolis/Metropolis-Light.woff") format("woff");
  font-weight: $font-weight-light;
}

@font-face {
  font-family: "Metropolis";
  src: local("Metropolis Medium"), local("Metropolis-Medium"),
    url("#{$path-fonts}/Metropolis/Metropolis-Medium.woff") format("woff");
  font-weight: $font-weight-medium;
}

@font-face {
  font-family: "Metropolis";
  src: local("Metropolis SemiBold"), local("Metropolis-SemiBold"),
    url("#{$path-fonts}/Metropolis/Metropolis-SemiBold.woff") format("woff");
  font-weight: $font-weight-semibold;
}

@font-face {
  font-family: "Metropolis";
  src: local("Metropolis Bold"), local("Metropolis-Bold"),
    url("#{$path-fonts}/Metropolis/Metropolis-Bold.woff") format("woff");
  font-weight: $font-weight-bold;
}

// IcoMoon

@font-face {
  font-family: "IcoMoon-Free";
  src: url("#{$path-fonts}/IcoMoon/IcoMoon-Free.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
