.thumbnail-grid {
  color: $white;
  h6 {
    font-size: 1rem;
    margin-bottom: 0;
    a {
      color: $white;
    }
  }
  p {
    font-size: .875rem;
  }
  .thumbnail-item {
    margin-bottom: 2.5rem;
    img {
      width: 80px;
      height: 80px;
      @include media-breakpoint-up(md) {
        width: 120px;
        height: 120px;
        object-fit: contain;
        background-color: white;
      }
    }
  }
  .media-body {
    margin-left: 20px;
  }
}