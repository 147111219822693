
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $font-weight-medium;
}

h1,
h5,
h6 {
  font-weight: $font-weight-semibold;
}

h1 {
  font-size: 2.875rem;
  color: $body-color-darkest;

  @include media-breakpoint-down(sm) {
    font-size: 1.625rem;
  }
}

h2 {
  color: $body-color-darkest;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

h3 {
  margin-top: 2rem;
}

h5 {
  font-size: 1.375rem;
  margin-bottom: 1.25rem;
}

strong {
  font-weight: $font-weight-semibold;
}
pre {
  display: block;
  font-size: $code-font-size;
  color: $pre-color;
  background-color: #f2f2f2;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;


  // Account for some code outputs that place code tags in pre tags
  code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
  }
}
