body {
  -webkit-font-smoothing: antialiased;
}


a {
  &.dark {
    color: $body-color !important;
  }
  &.light {
    color: $white !important;
  }
}