.site-header {
  background: $header-background;

  .site-header-content {
    position: relative;
    display: flex;
    margin: 0 auto;
    padding: 17px 0 18px 0;
    max-width: $section-content-max-width;
    @include media-breakpoint-down(lg) {
      padding: {
        left: $grid-gutter-width / 2 + $section-padding-x;
        right: $grid-gutter-width / 2 + $section-padding-x;
      }  
    }
  }

  .logo {
    height: 45px;
    width: auto;
  }

  .nav-mobile-link {
    display: none;
    position: absolute;
    top: 50%;
    right: 30px;
    margin-top: -6px;

    a {
      display: block;
      width: 23px;
      height: 12px;
      border-top: 4px solid $header-foreground;
      border-bottom: 4px solid $header-foreground;
      &.selected {
        border-color: $header-foreground-selected;
      }
    }

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }

  .nav-menu {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    margin-left: 50px;
    padding: 0;

    @include media-breakpoint-down(sm) {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      margin: 0;
      padding: 30px;
      min-height: 250px;
      background: $header-background;
      box-shadow: 3px 2px 4px 2px rgba(0,0,0,0.25);

      &.on {
        display: block;
      }

      li {
        font-size: 1.5rem;
      }
    }

    li {
      margin-right: 35px;
      a {
        font-weight: $font-weight-light;
        font-size: .875rem;
      }
      &:last-child {
        margin-right: 0;
      }

      &.selected {
        a {
          color: $header-foreground-selected;
          font-weight: $font-weight-semibold;
          &:hover {
            color: $header-foreground-selected;
            text-decoration: none;
          }
        }
      }

      a {
        color: $header-foreground;

        &:hover {
          color: $header-foreground;
          text-decoration: underline;
        }
      }
    }
  }
}